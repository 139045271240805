import React, { useRef, useState } from 'react'
import { OutsideClickHandler } from '../../../components'
import RangeFilterForm from './RangeFilterForm'

import css from './RangeFilter.module.css'
import { injectIntl } from 'react-intl'
import classNames from 'classnames'

const RangeFilterPopup = props => {
  const {
    id,
    min,
    max,
    intl,
    label,
    queryParam,
    initialValues,
    onSubmit,
    options
  } = props

  const KEY_CODE_ESCAPE = 27

  const [isOpen, setIsOpen] = useState(false)
  const filter = useRef()
  const filterContent = useRef()

  const parseRange = range => {
    const values = !!range ? range.toString().split(',') : []
    
    const [minValue, maxValue] = values.length === 1
      ? [values[0], values[0]]
      : values

    return !!range && minValue != null && maxValue != null 
      ? minValue != maxValue 
        ? { minValue: Number(minValue), maxValue: Number(maxValue) - 1 }
        : { minValue, maxValue }
      : {}
  }

  const formatQuery = (range) => {
    const { minValue, maxValue } = range || {}

    const value = minValue != null && maxValue != null 
      ? minValue >= maxValue 
        ? minValue 
        : `${Number(minValue)},${Number(maxValue) + 1}` 
      : null

    return { [queryParam]: value }
  }

  const handleSubmit = (values) => {
    setIsOpen(false)
    onSubmit(formatQuery(values))
  }

  const handleClear = () => {
    setIsOpen(false)
    onSubmit(formatQuery(null))
  }

  const handleCancel = () => {
    setIsOpen(false)
    onSubmit(initialValues)
  }

  const handleBlur = () => {
    setIsOpen(false)
  }

  const handleKeyDown = (e) => {
    if (e.keyCode === KEY_CODE_ESCAPE) {
      toggleOpen();
    }
  }

  const toggleOpen = () => {
    setIsOpen(!isOpen)
  }

  const positionStyleForContent = () => {
    if (filter.current && filterContent.current) {
      const distanceToRight = window.innerWidth - filter.current.getBoundingClientRect().right;
      const labelWidth = filter.current.offsetWidth;
      const contentWidth = filterContent.current.offsetWidth;
      const contentWidthBiggerThanLabel = contentWidth - labelWidth;
      const renderToRight = distanceToRight > contentWidthBiggerThanLabel;
      const contentPlacementOffset = -14

      const offset = renderToRight
        ? { left: contentPlacementOffset }
        : { right: contentPlacementOffset };
      
      const minWidth = contentWidth < labelWidth ? { minWidth: labelWidth } : null;

      return { ...offset, ...minWidth };
    }
    return {};
  }

  const initialRange =
    initialValues && initialValues[queryParam] ? parseRange(initialValues[queryParam]) : {}

  const { minValue, maxValue } = initialRange

  const hasInitialValues = initialValues && minValue && maxValue
  const labelStyles = hasInitialValues ? css.labelSelected : css.label

  const filterLable = hasInitialValues 
    ? intl.formatMessage(
        { id: 'search_page.range_filter.label.selected' },
        { minValue: minValue, maxValue: maxValue }
      )
    : label

  const popupClasses = classNames(css.popup, { [css.isOpenAsPopup]: isOpen });
  const contentStyle = positionStyleForContent()

  return(
    <OutsideClickHandler onOutsideClick={handleBlur}>
      <div
        className={css.root}
        onKeyDown={(e) => handleKeyDown(e)}
        ref={filter}
      >
        <button className={labelStyles} onClick={() => toggleOpen()}>
          {filterLable}
        </button>
        <div className={popupClasses} ref={filterContent} style={contentStyle}>
          <RangeFilterForm
            id={id}
            initialValues={hasInitialValues ? initialRange : { minValue: min, maxValue: max }}
            onSubmit={handleSubmit}
            onClear={handleClear}
            onCancel={handleCancel}
            intl={intl}
            min={min}
            max={max}
            showAsPopup
            isOpen={isOpen}
            label={label}
            queryParam={queryParam}
            options={options}
            forwardedRef={filterContent}
            ref={filterContent}
            style={contentStyle}
          />
        </div>
      </div>
    </OutsideClickHandler>
  )
}

export default injectIntl(RangeFilterPopup)
