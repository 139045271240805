import classNames from 'classnames'
import React from 'react'
import { NamedLink } from '../../../../components'
import { createSlug } from '../../../../util/urlHelpers'
import css from './RecentItemCard.module.css'
import config from '../../../../config';
import { findHumanizedValueFromFilterConfig } from '../../../../util/search'

import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import Spacer, { HORIZONTALL_AXIS } from '../../../../components/Spacer/Spacer'

import LikeBlankIcon from '../../../../assets/icons/hearts/blank-24.png'
import LikeFilledIcon from '../../../../assets/icons/hearts/filled-24.png'
import { formatMoney } from '../../../../util/currency'

const RecentItemCard = props => {
  const { 
    listing,
    rootClassName,
    rootImageClassName,
    filterConfig,
    intl,
    history,
    likedListingsIds,
    addUserFavorite,
    removeUserFavorite,
    currentUserId
   } = props

  const { title, price, publicData } = listing.attributes
  const { frameSize, frameMaterial, condition, selectedImage, listingType } = publicData
  const id = listing.id.uuid
  const slug = createSlug(title)
  
  const firstImageIndex = selectedImage || 0
  
  const image = listing.images && listing.images.length > 0 
    ? listing.images[firstImageIndex] === undefined 
      ? listing.images[0]
      : listing.images[firstImageIndex] 
    : null
  
    const imageUrl = image != null ? image.attributes.variants['scaled-large'].url : null

  const classes = classNames(rootClassName || css.root)
  const imageClasses = classNames(rootImageClassName || css.listingImage)

  const frameMaterialValue = frameMaterial && intl.formatMessage({ id: `frame_material.${frameMaterial}` })
  const frameSizeValue = frameSize && findHumanizedValueFromFilterConfig(filterConfig, 'frameSize', frameSize)
  const conditionValue = condition && intl.formatMessage({ id: `condition.${condition}` })

  const onLikeClick = (e) => {
    e.stopPropagation();
    e.preventDefault();

    likedListingsIds.includes(id) ? removeUserFavorite(id) : addUserFavorite(id, listingType)
  }

  const LikeButtonComponent = () => (
    <div className={css.likeButtonWrapper} onClick={(e) => onLikeClick(e)}>
      {
        likedListingsIds.includes(id)
          ? <img className={css.likeIcon} src={LikeFilledIcon}/>
          : <img className={css.likeIcon} src={LikeBlankIcon}/>
      }
      <img/>
    </div>
  )

  return (
    <NamedLink 
      className={classes}
      name="ListingPageWithReturn"
      params={{ id, slug }}
      state={{ prevPath: history.location.pathname, search: history.location.search }}
    >
      <div className={css.imageWrapper}>
        <img className={imageClasses} src={imageUrl} loading='lazy'></img>
        <Spacer size={12}/>
        <div className={css.itemDetails}>
          { currentUserId && currentUserId != listing.author.id.uuid ? <LikeButtonComponent/> : null }
          <div className={css.itemBadges}>
            { listingType === 'bike'
              ? (
                <>
                <div className={css.badgeRoot}>
                  <span className={css.badgeText}>{frameSizeValue}</span>
                </div>
                <Spacer axis={HORIZONTALL_AXIS} size={12}/>
                <div className={css.badgeRoot}>
                  <span className={css.badgeText}>{frameMaterialValue}</span>
                </div>
                </>
              ) : (
                <div className={css.badgeRoot}>
                  <span className={css.badgeText}>{conditionValue}</span>
                </div>
              )
            }
          </div>
          <Spacer size={8}/>
          <h3 className={css.itemTitle}>{title}</h3>
          <span className={css.itemPrice}>{formatMoney(intl, price)}</span>
        </div>
      </div>
    </NamedLink>
  )
}

RecentItemCard.defaultProps = {
  rootClassName: null,
  rootImageClassName: null,
  filterConfig: config.custom.filters,
}

export default compose(withRouter)(RecentItemCard)
