import React, { Component } from 'react'

import { injectIntl } from '../../../util/reactIntl'
import { parseSelectFilterOptions } from '../../../util/search'

import FilterPlain from '../FilterPlain/FilterPlain'
import FilterPopup from '../FilterPopup/FilterPopup'

import css from './DropDownFilter.module.css'
import FieldDropdownSelect from '../../EditListingPage/EditListingWizard/FieldDropdownSelect'

const getQueryParamName = queryParamNames => {
  return Array.isArray(queryParamNames) ? queryParamNames[0] : queryParamNames;
};

// Format URI component's query param: { pub_key: 'has_all:a,b,c' }
const format = (selectedOption, queryParamName, schemaType, searchMode) => {
  const hasOptionsSelected = selectedOption != undefined
  if (selectedOption == 'all') return null
  const mode = schemaType === 'multi-enum' && searchMode ? `${searchMode}:` : '';
  const value = hasOptionsSelected ? `${mode}${selectedOption}` : null;
  return { [queryParamName]: value };
};

class DropDownFilter extends Component {
  constructor(props) {
    super(props);

    this.filter = null;
    this.filterContent = null;

    this.positionStyleForContent = this.positionStyleForContent.bind(this);
  }

  positionStyleForContent() {
    if (this.filter && this.filterContent) {
      // Render the filter content to the right from the menu
      // unless there's no space in which case it is rendered
      // to the left
      const distanceToRight = window.innerWidth - this.filter.getBoundingClientRect().right;
      const labelWidth = this.filter.offsetWidth;
      const contentWidth = this.filterContent.offsetWidth;
      const contentWidthBiggerThanLabel = contentWidth - labelWidth;
      const renderToRight = distanceToRight > contentWidthBiggerThanLabel;
      const contentPlacementOffset = this.props.contentPlacementOffset;

      const offset = renderToRight
        ? { left: contentPlacementOffset }
        : { right: contentPlacementOffset };
      // set a min-width if the content is narrower than the label
      const minWidth = contentWidth < labelWidth ? { minWidth: labelWidth } : null;

      return { ...offset, ...minWidth };
    }
    return {};
  }

  render() {
    const {
      rootClassName,
      className,
      id,
      name,
      label,
      isOpen,
      options,
      initialValues,
      contentPlacementOffset,
      onSubmit,
      queryParamNames,
      schemaType,
      searchMode,
      intl,
      showAsPopup,
      translationGroup,
      translatableLabels,
      ...rest
    } = this.props

    const queryParamName = getQueryParamName(queryParamNames);
    const hasInitialValues = !!initialValues && !!initialValues[queryParamName];
    const selectedOptions = hasInitialValues
      ? parseSelectFilterOptions(initialValues[queryParamName]?.toString())
      : [];

    const contentStyle = this.positionStyleForContent()

    const namedInitialValues = { [name]: selectedOptions }

    const handleSubmit = values => {
      const usedValue = values ? values[name] : values
      onSubmit(format(usedValue, queryParamName, schemaType, searchMode));
    };

    return showAsPopup ? (
      <FilterPopup
        rootClassName={rootClassName}
        popupClassName={css.popupSize}
        name={name}
        label={label}
        isSelected={hasInitialValues}
        id={`${id}.popup`}
        showAsPopup
        contentPlacementOffset={contentPlacementOffset}
        onSubmit={handleSubmit}
        initialValues={namedInitialValues}
        keepDirtyOnReinitialize
        {...rest}
      >
        <FieldDropdownSelect
          id={`${id}-checkbox-group`}
          name={name}
          options={options}
          intl={intl}
          translatableLabels={translatableLabels}
          placeholder={intl.formatMessage({ id: `${translationGroup}.placeholder` })}
        />
      </FilterPopup>
    ) : (
      <FilterPlain
        className={className}
        rootClassName={rootClassName}
        label={label}
        isOpen={isOpen}
        labelSelection=''
        isSelected={hasInitialValues}
        id={`${id}.plain`}
        liveEdit
        contentPlacementOffset={contentStyle}
        onSubmit={handleSubmit}
        initialValues={namedInitialValues}
        {...rest}
      >
        <FieldDropdownSelect
          id={`${id}-checkbox-group`}
          name={name}
          options={options}
          intl={intl}
          translatableLabels={translatableLabels}
          placeholder={intl.formatMessage({ id: `${translationGroup}.placeholder` })}
        />
      </FilterPlain>
    )
  }
}

export default injectIntl(DropDownFilter)
