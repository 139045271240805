import React from 'react'
import RangeFilterPopup from './RangeFilterPopup';
import RangeFilterPlain from './RangeFilterPlain';

const RangeFilter = props => {
  const {
    showAsPopup,
    queryParamNames,
    ...rest 
  } = props

  const queryParam = Array.isArray(queryParamNames)
    ? queryParamNames[0]
    : typeof queryParamNames === 'string'
    ? queryParamNames
    : undefined

  return showAsPopup
    ? <RangeFilterPopup showAsPopup={showAsPopup} queryParam={queryParam} {...rest} />
    : <RangeFilterPlain liveEdit showAsPopup={showAsPopup} queryParam={queryParam} {...rest} />
}

RangeFilter.defaultProps = {
  showAsPopup: false,
};

export default RangeFilter;
