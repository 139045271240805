import classNames from 'classnames';
import React from 'react'
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { injectIntl } from 'react-intl';

import css from '../RangeFilter/RangeFilterForm.module.css'
import { Form } from '../../../components';
import MultiLevelSelect from './MultiLevelSelect';

const MultiLevelFilterFormComponent = props => {
  const { onChange, onSubmit, onCancel, onClear, queryParam, ...rest } = props;

  const handleSubmit = values => {
    return onSubmit(values)
  }

  const formCallbacks = { onSubmit: handleSubmit, onCancel, onClear }

  return (
    <FinalForm
      {...rest}
      {...formCallbacks}
      render={formRenderProps => {
        const {
          form,
          handleSubmit,
          id,
          onCancel,
          onClear,
          showAsPopup,
          isOpen,
          label,
          intl,
          options,
          liveEdit,
          initialValues
        } = formRenderProps

        const clear = intl.formatMessage({ id: 'search_page.price_filter.clear' })
        const cancel = intl.formatMessage({ id: 'search_page.price_filter.cancel' })
        const submit = intl.formatMessage({ id: 'search_page.price_filter.submit' })

        const handleCancel = () => {
          form.reset()
          onCancel()
        }

        const handleChange = formState => {
          if (formState.dirty) {
            onSubmit(formState.values);
          }
        };

        const classes = classNames(css.root, {
          [css.isOpenAsPopup]: showAsPopup && isOpen,
          [css.plain]: !showAsPopup,
          [css.isOpen]: !showAsPopup && isOpen,
        })

        return (
          <Form
            className={classes}
            onSubmit={handleSubmit}
          >
            <div className={css.contentWrapper}>
              <MultiLevelSelect
                id={id}
                name={id}
                options={options}
                intl={intl}
                initialValues={initialValues}
              />
              
              {liveEdit ? (
                <FormSpy onChange={handleChange} subscription={{ values: true, dirty: true }} />
              ) : (
                <div className={css.buttonsWrapper}>
                  <button className={css.clearButton} type="button" onClick={onClear}>
                    {clear}
                  </button>
                  <button className={css.cancelButton} type="button" onClick={handleCancel}>
                    {cancel}
                  </button>
                  <button className={css.submitButton} type="submit">
                    {submit}
                  </button>
                </div>
              )}
            </div>
          </Form>
        )
      }}
    />
  )
}

const MultiLevelFilterForm = injectIntl(MultiLevelFilterFormComponent)

export default MultiLevelFilterForm
